import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

let path = document.querySelector("#Pfad_191");
let length = path.getTotalLength();
// console.log(length);

path.style.strokeDasharray = length + ' ' + length;
path.style.strokeDashoffset = length;

let path2 = document.querySelector("#Pfad_190");
let length2 = path2.getTotalLength();
// console.log(length);

path2.style.strokeDasharray = length2 + ' ' + length2;
path2.style.strokeDashoffset = length2;

let path3 = document.querySelector("#Pfad_192");
let length3 = path3.getTotalLength();
// console.log(length);

path3.style.strokeDasharray = length3 + ' ' + length3;
path3.style.strokeDashoffset = length3;

